import i18n from 'i18next'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'
import en from './i18n/en.json'
import ru from './i18n/ru.json'
import store from './store'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en
  },
  ru: {
    translation: ru
  }
}

i18n
  .use(ICU)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: store.getState().locale.locale.name,
    keySeparator: '|', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
