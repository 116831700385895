import store from '../index'
import {
  Language,
  MainTaskType,
  OrderedTaskStep,
  PublishTaskSection,
  Task,
  TaskStep,
  UpdateTaskSection,
  VerificationTaskSection,
  GoogleAccountTaskSection
} from './initialState'
import { checkAgeLimits, numberTaskSteps, setDefaultLangFirst, allowGoogleAccountInVerification } from '../../utils/helpers'
import { axiosV3 } from '../../axios'
import Notification from '../../components/notification/Notification'
import i18n from 'i18next'
import {setGoogleAdminEmail, setSelectedTask, setStartStepIndex, updateAvailablePhone} from './actions'
import { PublisherTaskType } from '../taskList/initialState'
import { get } from 'idb-keyval'


type Response<T> = {
  data: T
  meta: { [key: string]: any }
}

export async function takeTaskToProcess({ id, start = false }: { id: string, start?: boolean }) {
  //  Взятие задачи в работу (перевод в статус "process")
  //  Перевод задачи на обновление с покупками в статус "process" после модерации покупок

  const inclusions = [
    'status',
    'task.type',
    'task.purchases',
    'application.type',
    'application.category',
    'application.status',
    'application.locales',
    'application.default_locale',
    'application.restriction'
  ].join(',')

  try {

    const response: Response<Task> = await axiosV3.post(`subtasks/${id}/process?include=${inclusions}`)

    const task = {
      ...response.data,
      ...response.meta
    }
    await store.dispatch(setSelectedTask(task))
    if (start) {
      store.dispatch(setStartStepIndex(id))
    }
    return true
  } catch (e) {
    if (e.logout || e.offline) return
    if (e.response?.status === 403) {
      Notification({ noticeType: 'warn', text: i18n.t('Not available task other user') })
      return
    }
    Notification({ noticeType: 'warn', text: i18n.t('Take task error') })
    console.info('Error in the method "takeTaskToProcess": ', e)
  }
}

export async function downloadTaskData(id: string | number) {
  //  Получение с бэка данных по задаче

  const inclusions = [
    'status',
    'task.type',
    'task.purchases',
    'application.type',
    'application.category',
    'application.status',
    'application.locales',
    'application.default_locale',
    'application.restriction'
  ].join(',')

  try {
    const response: Response<Task> = await axiosV3.get(`subtasks/${id}?include=${inclusions}`)

    const task = {...response.data, ...response.meta}
    const failedTask = await get('failedTask')
    if (task.type === PublisherTaskType.VERIFICATION) {
      store.dispatch(updateAvailablePhone(failedTask !== task.id ? !!task?.google_account_password : false))
    }
    if (task.type === PublisherTaskType.PUBLICATION) task.showReserveEmailSteps = await getGoogleAdminEmail(task.id)
    store.dispatch(setSelectedTask(task))
  } catch (e) {
    if (e.logout || e.offline) return
    Notification({ noticeType: 'warn', text: i18n.t('Download task error') })
    console.info('Error in the method "downloadTaskData": ', e)
  }
}

export function getStepsForVerificationTask(): Array<OrderedTaskStep> {
  //  Формирование массива с настройками шагов инструкции для задачи на верификацию аккаунта

  const isShowGoogleAccountsSteps: boolean = allowGoogleAccountInVerification()

  let steps: Array<TaskStep>
  const localeName: string = store.getState().locale.locale.name

  const getPassportStep = (): Array<TaskStep> => {
    const showAdditionalStep: boolean = store.getState().types.needUploadPassport
    if (!showAdditionalStep) return []
    return [{
      componentPath: 'verification/Guide4_1',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE,
        linkId: 'passport',
        blockExecution: true,
        stepsToBeCompleted: ['compensation']
      }
    }]
  }

  const getVerificationGuides = (): Array<TaskStep> => {
    if (localeName !== 'ru') return []

    return [
      {
        componentPath: 'verification/VerificationGuide1',
        stepOptions: {
          section: VerificationTaskSection.REG_IN_GOOGLE,
          stepsToBeCompleted: ['compensation']
        }
      },
      {
        componentPath: 'verification/VerificationGuide2',
        stepOptions: {
          section: VerificationTaskSection.REG_IN_GOOGLE,
          stepsToBeCompleted: ['compensation']
        }
      }
    ]
  }

  steps = [
    {
      componentPath: 'verification/Guide1_0',
      stepOptions: {
        section: VerificationTaskSection.NONE
      }
    },
    {
      componentPath: 'verification/Guide1_1',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide1_2',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide2_1',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide2_2',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide3',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide3_1',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide3_2',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide4',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide5',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide6',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide7',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide8',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide9',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide9_1',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide9_2',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide9_3',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    {
      componentPath: 'verification/Guide10',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE,
        linkId: 'compensation',
        blockExecution: true
      }
    },
    {
      componentPath: 'verification/Guide11',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE,
        stepsToBeCompleted: ['compensation']
      }
    },
    {
      componentPath: 'verification/Guide12',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE
      }
    },
    // ...getVerificationGuides(),
    // ...getPassportStep(),
    {
      componentPath: 'verification/Guide13',
      stepOptions: {
        section: VerificationTaskSection.REG_IN_GOOGLE,
        linkId: 'verification',
        blockExecution: true,
        isLastStep: !isShowGoogleAccountsSteps,
        stepsToBeCompleted: ['compensation', 'passport']
      }
    },
    ...getRegistrationGuide(VerificationTaskSection, allowGoogleAccountInVerification()),
    ...getPasswdGuide(VerificationTaskSection, allowGoogleAccountInVerification())
  ]

  return numberTaskSteps(VerificationTaskSection, steps)
}

export function getStepsForPublishTask(task: Task): Array<OrderedTaskStep> {
  //  Формирование массива с настройками шагов инструкции для задачи на публикацию

  const ageIsLess13: boolean = checkAgeLimits(13, 'less', task.application.age_limit)
  const hasCSV: boolean = !!task.application.data_safety_file
  const showReserveEmailSteps: boolean = !!task.showReserveEmailSteps
  const userWhatsapp = store.getState().user.userData.whatsapp

  let steps: Array<TaskStep>
  const sortedLanguage: Array<Language> = setDefaultLangFirst(task.application.locales, task.application.default_locale)

  const getLocaleSteps = (): Array<TaskStep> => {
    const rawLocaleSteps = sortedLanguage.map((lang: Language, index: number): Array<TaskStep> => {
      let nextLangSteps: Array<TaskStep> = []
      if (index > 0) {
        nextLangSteps = [
          {
            componentPath: 'publish/Guide17',
            stepOptions: {
              section: PublishTaskSection.MAIN_STORE_LISTING
            }
          },
          {
            componentPath: 'publish/Guide18',
            stepOptions: {
              section: PublishTaskSection.MAIN_STORE_LISTING,
              langName: lang.name
            }
          }
        ]
      }

      return [
        ...nextLangSteps,
        {
          componentPath: 'publish/Guide11',
          stepOptions: {
            section: PublishTaskSection.MAIN_STORE_LISTING,
            langName: lang.name
          }
        },
        {
          componentPath: 'publish/Guide12',
          stepOptions: {
            section: PublishTaskSection.MAIN_STORE_LISTING,
            langName: lang.name
          }
        },
        {
          componentPath: 'publish/Guide13',
          stepOptions: {
            section: PublishTaskSection.MAIN_STORE_LISTING,
            langName: lang.name
          }
        },
        {
          componentPath: 'publish/Guide14',
          stepOptions: {
            section: PublishTaskSection.MAIN_STORE_LISTING,
            langName: lang.name
          }
        },
        {
          componentPath: 'publish/Guide15',
          stepOptions: {
            section: PublishTaskSection.MAIN_STORE_LISTING,
            langName: lang.name
          }
        },
        {
          componentPath: 'publish/Guide16',
          stepOptions: {
            section: PublishTaskSection.MAIN_STORE_LISTING
          }
        }
      ]
    })
    return rawLocaleSteps.flat()
  }

  steps = [
    {
      componentPath: 'publish/Guide55',
      stepOptions: {
        section: !userWhatsapp ? PublishTaskSection.GO_TO_GOOGLE : null,
        blockExecution: true
      }
    },
    {
      componentPath: 'Guide1',
      stepOptions: {
        section: PublishTaskSection.GO_TO_GOOGLE,
        blockExecution: true
      }
    },
    {
      componentPath: 'publish/Guide7',
      stepOptions: {
        section: PublishTaskSection.MAIN_STORE_LISTING
      }
    },
    {
      componentPath: 'publish/Guide8',
      stepOptions: {
        section: PublishTaskSection.MAIN_STORE_LISTING
      }
    },
    {
      componentPath: 'publish/Guide9',
      stepOptions: {
        section: PublishTaskSection.MAIN_STORE_LISTING
      }
    },
    {
      componentPath: 'publish/Guide10',
      stepOptions: {
        section: PublishTaskSection.MAIN_STORE_LISTING
      }
    },
    ...getLocaleSteps(),
    {
      componentPath: 'publish/Guide19',
      stepOptions: {
        section: PublishTaskSection.STORE_SETTINGS
      }
    },
    {
      componentPath: 'publish/Guide20',
      stepOptions: {
        section: PublishTaskSection.STORE_SETTINGS
      }
    },
    {
      componentPath: 'publish/Guide26',
      stepOptions: {
        section: PublishTaskSection.PRODUCTION
      }
    },
    {
      componentPath: 'publish/Guide27',
      stepOptions: {
        section: PublishTaskSection.PRODUCTION
      }
    },
    {
      componentPath: 'publish/Guide21',
      stepOptions: {
        section: PublishTaskSection.PRODUCTION
      }
    },
    {
      componentPath: 'publish/Guide24',
      stepOptions: {
        section: PublishTaskSection.PRODUCTION
      }
    },
    {
      componentPath: 'publish/Guide25',
      stepOptions: {
        section: PublishTaskSection.PRODUCTION
      }
    },
    {
      componentPath: 'publish/Guide28',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide29',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide30',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide31',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide32',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide33',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide34',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide35',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide36',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide41',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide37',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide38',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: !ageIsLess13 ? 'publish/Guide39' : 'publish/Guide39_1',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide40',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42_1',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42_2',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42_3',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42_4',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42_5',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'csv/CsvGuide_2',
      stepOptions: {
        section: hasCSV ? PublishTaskSection.DASHBOARD : null
      }
    },
    {
      componentPath: 'csv/CsvGuide_3',
      stepOptions: {
        section: hasCSV ? PublishTaskSection.DASHBOARD : null
      }
    },
    {
      componentPath: 'csv/CsvGuide_4',
      stepOptions: {
        section: hasCSV ? PublishTaskSection.DASHBOARD : null
      }
    },
    {
      componentPath: 'csv/CsvGuide_5',
      stepOptions: {
        section: hasCSV ? PublishTaskSection.DASHBOARD : null
      }
    },
    {
      componentPath: !hasCSV ? 'publish/Guide42_6' : 'csv/CsvGuide_6',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42_7',
      stepOptions: {
        section: !hasCSV ? PublishTaskSection.DASHBOARD : null
      }
    },
    {
      componentPath: !hasCSV ? 'publish/Guide42_8' : 'csv/CsvGuide_7',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42_9',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42_10',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42_11',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide42_12',
      stepOptions: {
        section: PublishTaskSection.DASHBOARD
      }
    },
    {
      componentPath: 'publish/Guide43',
      stepOptions: {
        section: PublishTaskSection.APP_CONTENT
      }
    },
    {
      componentPath: 'publish/Guide44',
      stepOptions: {
        section: PublishTaskSection.APP_CONTENT
      }
    },
    {
      componentPath: 'publish/Guide45',
      stepOptions: {
        section: PublishTaskSection.APP_CONTENT
      }
    },
    {
      componentPath: 'publish/Guide46',
      stepOptions: {
        section: PublishTaskSection.APP_CONTENT
      }
    },
    {
      componentPath: 'publish/Guide47',
      stepOptions: {
        section: PublishTaskSection.APP_CONTENT,
        linkId: 'moderation',
        isLastStep: !showReserveEmailSteps,
        blockExecution: true
      }
    },
    {
      componentPath: 'publish/Guide48',
      stepOptions: {
        section: showReserveEmailSteps ? PublishTaskSection.APP_CONTENT : null,
      }
    },
    {
      componentPath: 'publish/Guide49',
      stepOptions: {
        section: showReserveEmailSteps ? PublishTaskSection.APP_CONTENT : null,
      }
    },
    {
      componentPath: 'publish/Guide50',
      stepOptions: {
        section: showReserveEmailSteps ? PublishTaskSection.APP_CONTENT : null,
      }
    },
    {
      componentPath: 'publish/Guide51',
      stepOptions: {
        section: showReserveEmailSteps ? PublishTaskSection.APP_CONTENT : null,
      }
    },
    {
      componentPath: 'publish/Guide52',
      stepOptions: {
        section: showReserveEmailSteps ? PublishTaskSection.APP_CONTENT : null,
      }
    },
    {
      componentPath: 'publish/Guide53',
      stepOptions: {
        section: showReserveEmailSteps ? PublishTaskSection.APP_CONTENT : null,
      }
    },
    {
      componentPath: 'publish/Guide54',
      stepOptions: {
        section: showReserveEmailSteps ? PublishTaskSection.APP_CONTENT : null,
        isLastStep: true,
        linkId: 'moderation',
        blockExecution: true
      }
    }
  ]

  return numberTaskSteps(PublishTaskSection, steps)
}

export function getStepsForUpdateTask(task: Task): Array<OrderedTaskStep> {
  //  Формирование массива с настройками шагов инструкции для задачи на обновление
  const hasCSV: boolean = !!task.application.data_safety_file

  const addingCountries = task.calcAddingCountries
  const removingCountries = task.calcRemovingCountries
  const isEditCountries = !!addingCountries?.length || !!removingCountries?.length
  const mainTaskType = task.task?.type?.name

  const isPurchases = mainTaskType === MainTaskType.UPDATE_PURCHASES

  let steps: Array<TaskStep>
  const getLocaleSteps = (): Array<TaskStep> => {
    const sortedLanguage: Array<Language> = setDefaultLangFirst(task.application.locales, task.application.default_locale)
    const rawLocaleSteps = sortedLanguage.map((lang: Language, index: number): Array<TaskStep> => {
      let additionalSteps: Array<TaskStep>
      let clearFormStep: Array<TaskStep> = []

      if ([
        MainTaskType.UPDATE_ASO_IMG,
        MainTaskType.UPDATE_ASO_IMG_ASSEMBLY,
        MainTaskType.UPDATE_IMG
      ].includes(mainTaskType)) {
        clearFormStep = [
          {
            componentPath: 'update/UpdateGuide3_1',
            stepOptions: {
              section: UpdateTaskSection.APP_DESCRIPTION
            }
          }
        ]
      }

      if (index === 0) {
        additionalSteps = [
          {
            componentPath: 'update/UpdateGuide3',
            stepOptions: {
              section: UpdateTaskSection.APP_DESCRIPTION
            }
          }
        ]
      } else {
        additionalSteps = [
          {
            componentPath: 'update/UpdateGuide9',
            stepOptions: {
              section: UpdateTaskSection.APP_DESCRIPTION
            }
          },
          {
            componentPath: 'update/UpdateGuide10',
            stepOptions: {
              section: UpdateTaskSection.APP_DESCRIPTION,
              langName: lang.name
            }
          },
          {
            componentPath: 'update/UpdateGuide11',
            stepOptions: {
              section: UpdateTaskSection.APP_DESCRIPTION,
              langName: lang.name
            }
          }
        ]
      }

      return [
        ...additionalSteps,
        ...clearFormStep,
        {
          componentPath: 'update/UpdateGuide4',
          stepOptions: {
            section: UpdateTaskSection.APP_DESCRIPTION,
            langName: lang.name
          }
        },
        {
          componentPath: 'update/UpdateGuide4_1',
          stepOptions: {
            section: UpdateTaskSection.APP_DESCRIPTION
          }
        },
        {
          componentPath: 'update/UpdateGuide5',
          stepOptions: {
            section: UpdateTaskSection.APP_DESCRIPTION,
            langName: lang.name
          }
        },
        {
          componentPath: 'update/UpdateGuide5_1',
          stepOptions: {
            section: UpdateTaskSection.APP_DESCRIPTION
          }
        },
        {
          componentPath: 'update/UpdateGuide6',
          stepOptions: {
            section: UpdateTaskSection.APP_DESCRIPTION,
            langName: lang.name
          }
        },
        {
          componentPath: 'update/UpdateGuide6_1',
          stepOptions: {
            section: UpdateTaskSection.APP_DESCRIPTION
          }
        },
        {
          componentPath: 'update/UpdateGuide7',
          stepOptions: {
            section: UpdateTaskSection.APP_DESCRIPTION,
            langName: lang.name
          }
        },
        {
          componentPath: 'update/UpdateGuide7_1',
          stepOptions: {
            section: UpdateTaskSection.APP_DESCRIPTION
          }
        },
        {
          componentPath: 'update/UpdateGuide8',
          stepOptions: {
            section: UpdateTaskSection.APP_DESCRIPTION,
            langName: lang.name
          }
        },
        {
          componentPath: 'update/UpdateGuide8_1',
          stepOptions: {
            section: UpdateTaskSection.APP_DESCRIPTION,
            langName: lang.name
          }
        }
      ]
    })
    return rawLocaleSteps.flat()
  }

  const getApkSteps = (): Array<TaskStep> => [
    {
      componentPath: 'update/UpdateGuide13',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide14',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide15',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide16',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide17',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    }
  ]

  const getPurchaseSteps = (): Array<TaskStep> => [
    {
      componentPath: 'update/UpdateGuide20',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide21',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide22',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide23',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide24',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide25',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide26',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide27',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide28',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide28_1',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide28_2',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide29',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide29_1',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide29_2',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide36',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide37',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide38',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide39',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide40',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide41',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide31',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide32',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide33',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    },
    {
      componentPath: 'update/UpdateGuide34',
      stepOptions: {
        section: UpdateTaskSection.APP_VERSIONS
      }
    }
  ]

  const getCsvSteps = (): Array<TaskStep> => {
    switch (mainTaskType) {
      case MainTaskType.UPDATE_ASSEMBLY:
      case MainTaskType.UPDATE_ASO_IMG_ASSEMBLY:
      case MainTaskType.UPDATE_PURCHASES:
        const typeSection = mainTaskType === MainTaskType.UPDATE_ASO_IMG_ASSEMBLY ? 'APP_DESCRIPTION' : 'APP_VERSIONS'
        return [
          {
            componentPath: 'csv/CsvGuide_1',
            stepOptions: {
              section: hasCSV ? UpdateTaskSection[typeSection] : null
            }
          },
          {
            componentPath: 'csv/CsvGuide_2',
            stepOptions: {
              section: hasCSV ? UpdateTaskSection[typeSection] : null
            }
          },
          {
            componentPath: 'csv/CsvGuide_3',
            stepOptions: {
              section: hasCSV ? UpdateTaskSection[typeSection] : null
            }
          },
          {
            componentPath: 'csv/CsvGuide_4',
            stepOptions: {
              section: hasCSV ? UpdateTaskSection[typeSection] : null
            }
          },
          {
            componentPath: 'csv/CsvGuide_5',
            stepOptions: {
              section: hasCSV ? UpdateTaskSection[typeSection] : null
            }
          },
          {
            componentPath: 'csv/CsvGuide_6',
            stepOptions: {
              section: hasCSV ? UpdateTaskSection[typeSection] : null
            }
          },
          {
            componentPath: 'csv/CsvGuide_7',
            stepOptions: {
              section: hasCSV ? UpdateTaskSection[typeSection] : null
            }
          }
        ]
      default:
        return []
    }
  }

  const sectionUpdate = [
    MainTaskType.UPDATE_ASSEMBLY,
    MainTaskType.UPDATE_ASO_ASSEMBLY,
    MainTaskType.UPDATE_PURCHASES
  ].includes(mainTaskType)
    ? UpdateTaskSection.APP_VERSIONS
    : UpdateTaskSection.APP_DESCRIPTION

  const getEditCountriesSteps = (): Array<TaskStep> => {
    return [
      {
        componentPath: 'update/UpdateGuide48',
        stepOptions: {
          section: !!removingCountries?.length ? sectionUpdate : null
        }
      },
      {
        componentPath: 'update/UpdateGuide49',
        stepOptions: {
          section: !!removingCountries?.length ? sectionUpdate : null,
          isLastStep: isPurchases && !addingCountries?.length
        }
      },
      {
        componentPath: 'update/UpdateGuide50',
        stepOptions: {
          section: !!addingCountries?.length && !!removingCountries?.length ? sectionUpdate : null
        }
      },
      {
        componentPath: 'update/UpdateGuide46',
        stepOptions: {
          section: !!addingCountries?.length && !removingCountries?.length ? sectionUpdate : null
        }
      },
      {
        componentPath: 'update/UpdateGuide47',
        stepOptions: {
          section: !!addingCountries?.length ? sectionUpdate : null,
          isLastStep: isPurchases
        }
      }
    ]
  }

  const getVariableSteps = (): Array<TaskStep> => {
    switch (mainTaskType) {
      case MainTaskType.UPDATE_ASO_TEXT_IMG:
      case MainTaskType.UPDATE_IMG:
        return getLocaleSteps()
      case MainTaskType.UPDATE_ASO_IMG:
        return [
          ...getEditCountriesSteps(),
          ...getLocaleSteps()
        ]
      case MainTaskType.UPDATE_ASSEMBLY:
        return [
          ...getEditCountriesSteps(),
          ...getCsvSteps(),
          ...getApkSteps()
        ]
      case MainTaskType.UPDATE_ASO_ASSEMBLY:
        return getApkSteps()
      case MainTaskType.UPDATE_ASO_IMG_ASSEMBLY:
        return [
          ...getEditCountriesSteps(),
          ...getCsvSteps(),
          ...getLocaleSteps(),
          ...getApkSteps()
        ]
      case MainTaskType.UPDATE_PURCHASES:
        return [
          ...getCsvSteps(),
          ...getPurchaseSteps()
        ]
      default:
        return []
    }
  }

  const getLastSteps = (): Array<TaskStep> => {
    if (isPurchases) {
      return [
        {
          componentPath: 'update/UpdateGuide35',
          stepOptions: {
            section: UpdateTaskSection.APP_VERSIONS,
            isLastStep: !isEditCountries && !hasCSV,
            linkId: 'moderation',
            blockExecution: true
          }
        },
        {
          componentPath: 'update/UpdateGuide44',
          stepOptions: {
            section: hasCSV ? UpdateTaskSection.APP_VERSIONS : null
          }
        },
        {
          componentPath: 'update/UpdateGuide45',
          stepOptions: {
            section: hasCSV ? UpdateTaskSection.APP_VERSIONS : null,
            isLastStep: !isEditCountries && hasCSV,
            linkId: 'moderation',
            blockExecution: true
          }
        },
        ...getEditCountriesSteps()
      ]
    }

    const section: UpdateTaskSection = [
      MainTaskType.UPDATE_ASO_IMG,
      MainTaskType.UPDATE_ASO_TEXT_IMG,
      MainTaskType.UPDATE_IMG
    ].includes(mainTaskType)
      ? UpdateTaskSection.APP_DESCRIPTION
      : UpdateTaskSection.APP_VERSIONS

    return [{
      componentPath: 'update/UpdateGuide18',
      stepOptions: {
        section,
        isLastStep: true,
        linkId: 'moderation',
        blockExecution: true
      }
    }]
  }

  steps = [
    {
      componentPath: 'Guide1',
      stepOptions: {
        section: UpdateTaskSection.GO_TO_GOOGLE
      }
    },
    {
      componentPath: 'update/UpdateGuide2',
      stepOptions: {
        section: sectionUpdate
      }
    },
    ...getVariableSteps(),
    ...getLastSteps()
  ]

  return numberTaskSteps(UpdateTaskSection, steps)
}

function getRegistrationGuide(sectionObj, isShowGoogleAccountsSteps: boolean = true) {
  return [
    {
      componentPath: 'googleAccount/Guide1',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.REG_GMAIL_ACCOUNT : null
      }
    },
    {
      componentPath: 'googleAccount/Guide2',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.REG_GMAIL_ACCOUNT : null
      }
    },
    {
      componentPath: 'googleAccount/Guide3',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.REG_GMAIL_ACCOUNT : null
      }
    },
    {
      componentPath: 'googleAccount/Guide4',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.REG_GMAIL_ACCOUNT : null,
        blockExecution: true
      }
    },
    {
      componentPath: 'googleAccount/Guide8',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.REG_GMAIL_ACCOUNT : null
      }
    },
    {
      componentPath: 'googleAccount/Guide9',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.REG_GMAIL_ACCOUNT : null
      }
    },
    {
      componentPath: 'googleAccount/Guide10',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.REG_GMAIL_ACCOUNT : null
      }
    },
  ]
}

function getPasswdGuide(sectionObj, isShowGoogleAccountsSteps: boolean = true) {
  return [
    {
      componentPath: 'googleAccount/Guide11',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.RESERVE_PASSWD : null
      }
    },
    {
      componentPath: 'googleAccount/Guide12',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.RESERVE_PASSWD : null
      }
    },
    {
      componentPath: 'googleAccount/Guide13',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.RESERVE_PASSWD : null
      }
    },
    {
      componentPath: 'googleAccount/Guide14',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.RESERVE_PASSWD : null,
        blockExecution: true
      }
    },
    {
      componentPath: 'googleAccount/Guide16',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.RESERVE_PASSWD : null
      }
    },
    {
      componentPath: 'googleAccount/Guide17',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.RESERVE_PASSWD : null
      }
    },
    {
      componentPath: 'googleAccount/Guide18',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.RESERVE_PASSWD : null
      }
    },
    {
      componentPath: 'googleAccount/Guide19',
      stepOptions: {
        section: isShowGoogleAccountsSteps ? sectionObj.RESERVE_PASSWD : null,
        isLastStep: true,
        linkId: 'gmail',
        blockExecution: true
      }
    }
  ]
}

export function getStepsForOtherTask() {
  //  Формирование массива с настройками шагов инструкции для задачи создания Google аккаунта

  let steps

  steps = [
    ...getRegistrationGuide(GoogleAccountTaskSection),
    ...getPasswdGuide(GoogleAccountTaskSection)
  ]

  return numberTaskSteps(GoogleAccountTaskSection, steps)
}

export async function getGoogleAdminEmail(id: string | number) {
  //  Получение с бэка email для тестирования задачи

  try {
    const response = await axiosV3.post(`subtasks/${id}/google-account`)

    const email = response.data.email
    store.dispatch(setGoogleAdminEmail(email))
    return true
  } catch (e) {
    if (e.logout || e.offline || e.response.status === 404) return
    Notification({ noticeType: 'warn', text: i18n.t('Get google admin email error') })
    console.info('Error in the method "getGoogleAdminEmail": ', e)
  }
}
